<template>
  <div>
    <div class="shifter" :style="`height: ${state != 'open' ? contentHeight : 0}px`" />
    <div class="wrapper" :data-open="state === 'open' ? 1 : 0">
      <div class="bg" @click="() => setState('half')" />
      <div
        ref="card"
        class="card"
        :data-state="isMove ? 'move' : state"
        :style="{ top: `${isMove ? y : calcY()}px` }"
      >
        <div class="contents" :style="`height: ${contentHeight}px`">
          <div class="expand" :class="{'collapsed': state == 'open'}" @click.stop.prevent="expand()" />
          <div class="close" @click.stop.prevent="close()" />
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    openY: {
      type: Number,
      default: 0.1
    },
    halfY: {
      type: Number,
      default: 0.8
    },
    defaultState: {
      type: String,
      default: 'close'
    },
    barColor: { // Added a bar color props
      type: String,
      default: 'rgba(0, 0, 0, .3)'
    }
  },
  data () {
    return {
      y: 0,
      startY: 0,
      contentHeight: 0,
      isMove: false,
      state: this.defaultState,
      rect: {}
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
    this.rect = this.$refs.card.getBoundingClientRect()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    expand () {
      if (this.state === 'half') {
        this.setState('open')
      } else {
        this.setState('half')
      }
    },
    close () {
      this.setState('close')
    },
    onResize () {
      this.rect = this.$refs.card.getBoundingClientRect()
    },
    height () {
      return this.$refs.card.clientHeight - this.calcY()
    },
    calcY () {
      switch (this.state) {
        case 'close':
          return this.rect.height
        case 'open':
          return this.rect.height * this.openY
        case 'half':
          return this.rect.height * this.halfY
        default:
          return this.y
      }
    },
    setState (state) {
      this.state = state
      this.contentHeight = this.height()
    }
  }
}
</script>

<style lang="scss" scoped>
.shifter {
  display: block;
  width: 100%;
  height: 0;
  transition: all .3s;
}

.close {
  position: absolute;
  right: 6px;
  top: 4px;
  width: 30px;
  z-index: 500;
  text-align: center;
  cursor: pointer;
  user-select: none;
  &:after{
    pointer-events: none;
    display: inline-block;
    content: "\00d7";
    font-size: 22px;
    color: #555;
  }
}
.wrapper {
  position: absolute;
  z-index: 5000;
}
.wrapper[data-open="1"] {
  position: fixed;
  top: 0;
  left: 0;
}

.wrapper[data-open="1"] .bg {
  display: block;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
}

.card {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: white;
  box-shadow: 0 -3px 4px rgba(0, 0, 0, .1);
  left: 0;
}

.card[data-state="half"], .card[data-state="open"], .card[data-state="close"] {
  transition: top .3s ease-out;
}

.card[data-state="close"] {
  box-shadow: none;
}

.contents {
  position: relative;
}

.bar {
  width: 45px;
  height: 8px;
  border-radius: 14px;
  margin: 0 auto;
  cursor: pointer;
}

.contents {
  box-sizing: border-box;
}

.expand {
  font-size: 11px;
  height: 26px;
  display: inline-block;
  position: absolute;
  right: 37px;
  top: 11px;
  width: 30px;
  z-index: 500;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
}

.expand::before, .expand::after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 1em;
  height: .16em;
  top: calc( (1em / 2 ) - .08em );
  background-color: #555;
  transition: 0.3s ease-in-out all;
  border-radius: 0.03em;
}

.expand::after {
  transform: rotate(90deg);
}

.collapsed.expand::after {
  transform: rotate(180deg);
}

.collapsed.expand::before {
  transform: rotate(90deg) scale(0);
}

</style>
